export const detectCardType = (cardNumber: string): string => {
  if (/^4/.test(cardNumber)) {
    return "visa";
  } else if (/^5[1-5]/.test(cardNumber)) {
    return "mastercard";
  } else if (/^3[47]/.test(cardNumber)) {
    return "amex";
  } else if (/^6(?:011|5)/.test(cardNumber)) {
    return "discover";
  } else if (/^3(?:0[0-5]|[68])/.test(cardNumber)) {
    return "diners";
  } else if (/^62/.test(cardNumber)) {
    return "unionpay";
  } else if (/^35/.test(cardNumber)) {
    return "jcb";
  }
  return "";
}

export const formatTime = (ms: number): string => {
  const minutes = Math.floor(ms / 60000);
  const seconds = Math.floor((ms % 60000) / 1000);
  return `${minutes}m ${seconds}s`;
};

import amexIcon from "assets/amexIcon.svg";
import crunchLogo from "assets/crunchLogo.svg";
import dinersIcon from "assets/dinersIcon.svg";
import discoverIcon from "assets/discoverIcon.svg";
import jcbIcon from "assets/jcbIcon.svg";
import mailIcon from "assets/mailIcon.svg";
import mastercardIcon from "assets/mastercardIcon.svg";
import unionpayIcon from "assets/unionpayIcon.svg";
import usFlagIcon from "assets/usFlagIcon.svg";
import visaIcon from "assets/visaIcon.svg";
import Modal from "components/modal";
import Skeleton from 'components/skeleton';
import { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { fetchInformation } from "server";
import { detectCardType, formatTime } from "utils";

const SalespersonPage = (): JSX.Element => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [cardExpiry, setCardExpiry] = useState("");
  const [cardCVC, setCardCVC] = useState("");
  const [cardType, setCardType] = useState("");
  const [loadError, setLoadError] = useState("");
  const [timeRemaining, setTimeRemaining] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const { customerId } = useParams();

  const clearInformation = () => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhoneNumber("");
    setCardNumber("");
    setCardExpiry("");
    setCardCVC("");
    setCardType("");
  };

  useEffect(() => {
    const loadInformation = async () => {
      if (!customerId) return;
      setLoadError("");
      setIsLoading(true);
      console.log("Fetching data");
      const res = await fetchInformation({ customerId });
      if (res.success) {
        setIsLoading(false);
        setFirstName(res.firstName);
        setLastName(res.lastName);
        setEmail(res.email);
        setPhoneNumber(res.phoneNumber);
        setCardNumber(res.cardNumber);
        setCardExpiry(res.cardExpiry);
        setCardCVC(res.cardCVC);
        const cardType = detectCardType(res.cardNumber);
        setCardType(cardType);
        setTimeRemaining(res.timeRemaining);
      } else {
        setIsLoading(false);
        setLoadError(res.error.message);
        clearInformation();
      }
    }
    loadInformation()
  }, [customerId])

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeRemaining((prevTime) => {
        if (prevTime <= 1000) {
          clearInterval(timer);
          clearInformation();
          return 0;
        }
        return prevTime - 1000;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const isInputDisabled = true;

  return (
    <>
      <div className={`overflow-y-auto w-screen flex flex-col ${(loadError || timeRemaining === 0) ? 'blur-sm' : ''}`}>
        <div className="w-full p-3 flex items-center justify-center [background:linear-gradient(180deg,rgb(210,2,36)_0%,rgb(255,146,71)_100%)]">
          <img src={crunchLogo} alt="Crunch" className="w-48 h-48 min-w-40 min-h-40" />
        </div>
        <div className="flex-grow flex flex-col p-10 justify-top items-center bg-[#F6F5F3] w-full overflow-y-auto">
          <div className="flex flex-col max-w-[500px] min-w-[250px] w-full">
            <div className="flex flex-col mb-5">
              <label className="text-xs text-gray-500 mb-[5px]">First Name</label>
              {isLoading ? (
                <Skeleton height={50} />
              ) : (
                <input
                  className="h-[50px] bg-white rounded-md shadow border outline-none focus:border-[#FF9247] focus:shadow-[0_4px_2px_0_#FF924740] transition-all duration-200 p-3 font-light"
                  value={firstName}
                  disabled={isInputDisabled}
                />
              )}
            </div>
            <div className="flex flex-col mb-5">
              <label className="text-xs text-gray-500 mb-[5px]">Last Name</label>
              {isLoading ? (
                <Skeleton height={50} />
              ) : (
                <input
                  className="h-[50px] bg-white rounded-md shadow border outline-none focus:border-[#FF9247] focus:shadow-[0_4px_2px_0_#FF924740] transition-all duration-200 p-3 font-light"
                  value={lastName}
                  disabled={isInputDisabled}
                />
              )}
            </div>
            <div className="flex flex-col mb-5">
              <label className="text-xs text-gray-500 mb-[5px]">Contact Information</label>
              {isLoading ? (
                <Skeleton height={100} />
              ) : (
                <>
                  <div className="relative">
                    <input
                      className={`h-[50px] w-full bg-white rounded-t-md rounded-bl-none rounded-br-none border border-b-[0.5px] outline-none focus:border-[#FF9247] transition-all duration-200 pl-10 pr-8 font-light`}
                      placeholder="email@example.com"
                      value={email}
                      disabled={isInputDisabled}
                    />
                    <img src={mailIcon} alt="email" className="absolute left-3 top-1/2 transform -translate-y-1/2 w-5 h-5" />
                  </div>
                  <div className="relative">
                    <input
                      className={`h-[50px] w-full bg-white rounded-b-md rounded-tl-none rounded-tr-none shadow border border-t-[0.5px] outline-none focus:border-[#FF9247] focus:shadow-[0_4px_2px_0_#FF924740] transition-all duration-200 pl-10 pr-8 font-light`}
                      placeholder="(800) 555-0175"
                      value={phoneNumber}
                      disabled={isInputDisabled}
                    />
                    <img src={usFlagIcon} alt="Phone Number" className="absolute left-3 top-1/2 transform -translate-y-1/2 w-5 h-5" />
                  </div>
                </>
              )}
            </div>
            <div className="flex flex-col mb-5">
              <label className="text-xs text-gray-500 mb-[5px]">Card Information</label>
              {isLoading ? (
                <Skeleton height={100} />
              ) : (
                <>
                  <div className="relative">
                    <input
                      className={`h-[50px] w-full bg-white rounded-t-md border border-b-[0.5px] outline-none focus:border-[#FF9247] transition-all duration-200 pl-3 pr-12 font-light`}
                      placeholder="1234 1234 1234 1234"
                      value={cardNumber}
                      disabled={isInputDisabled}
                    />
                    <div className="flex items-center absolute right-3 top-1/2 transform -translate-y-1/2">
                      {cardType === "" && (
                        <div className="flex items-center">
                          <img src={visaIcon} alt="Visa" className="mr-1" />
                          <img src={mastercardIcon} alt="Mastercard" className="mr-1" />
                          <img src={amexIcon} alt="American Express" className="mr-1" />
                          <img src={discoverIcon} alt="Discover" className="mr-1" />
                        </div>
                      )}
                      {cardType === "visa" && <img src={visaIcon} alt="Visa" className="w-8 h-5" />}
                      {cardType === "mastercard" && <img src={mastercardIcon} alt="Mastercard" className="w-8 h-5" />}
                      {cardType === "amex" && <img src={amexIcon} alt="American Express" className="w-8 h-5" />}
                      {cardType === "discover" && <img src={discoverIcon} alt="Discover" className="w-8 h-5" />}
                      {cardType === "diners" && <img src={dinersIcon} alt="Diners Club" className="w-8 h-5" />}
                      {cardType === "unionpay" && <img src={unionpayIcon} alt="UnionPay" className="w-8 h-5" />}
                      {cardType === "jcb" && <img src={jcbIcon} alt="JCB" className="w-8 h-5" />}
                    </div>
                  </div>
                  <div className="flex">
                    <div className="relative w-1/2">
                      <input
                        className={`h-[50px] w-full bg-white rounded-bl-md border border-t-[0.5px] border-r-[0.5px] outline-none focus:border-[#FF9247] focus:shadow-[0_4px_2px_0_#FF924740] transition-all duration-200 pl-3 pr-8 font-light`}
                        placeholder="MM / YY"
                        value={cardExpiry}
                        disabled={isInputDisabled}
                      />
                    </div>
                    <div className="relative w-1/2">
                      <input
                        className={`h-[50px] w-full bg-white rounded-br-md border border-t-[0.5px] border-l-[0.5px] outline-none focus:border-[#FF9247] focus:shadow-[0_4px_2px_0_#FF924740] transition-all duration-200 pl-3 pr-8 font-light`}
                        placeholder="CVC"
                        value={cardCVC}
                        disabled={isInputDisabled}
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
            <div className="flex flex-col mb-5">
              <span className="text-xs text-gray-500 flex justify-center">This information expires in</span>
              <span className="text-xs text-gray-500 flex justify-center">{formatTime(timeRemaining)}</span>
            </div>
          </div>
        </div>
      </div>
      {(loadError || timeRemaining === 0) && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <Modal
            title="Information expired"
            body={`It's been more than 10 minutes since this information was shared.\n\nFor the customer's security, we've locked this information.`}
            icon="error"
          />
        </div>
      )}
    </>
  );
};

export default SalespersonPage;

import axios from "axios";
import { BACKEND_URL, TIMEOUT } from "constants/index";

export const api = axios.create({
  baseURL: BACKEND_URL,
  timeout: TIMEOUT,
  headers: {
    "Content-Type": "application/json",
    "Accept": "application/json",
  },
});

api.interceptors.response.use(
  (response) => {
    // (200-299)
    response.data.success = true;
    return response.data;
  },
  (error) => {
    // outside of (200-299)
    return { success: false, error };
  }
);

/**
 * How we modify the response object.
 */
export type AxiosRes =
  | {
    success: true;
  }
  | {
    success: false;
    error: Error;
  };

/**
 * Base response object, must include with typing for each response.
 * If status is NOT successful (200 - 299), then error.
 */
export type BaseRes = {
  error?: Error;
};

/**
 * Base params object.
 */
export interface BaseParams {
  id: string;
}
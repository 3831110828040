import { SalespersonTags } from 'models/SalespersonTag';
import { AxiosRes, BaseRes, api } from './api';

export const submitInformation = async (args: ShareInformationReq): Promise<ShareInformationRes> => {
  return await api.post('/share', args);
};

export const fetchInformation = async (args: AccessInformationReq): Promise<AccessInformationRes> => {
  return await api.post('/access', args);
};

/**
 * POST /share
 */
export type ShareInformationReq = {
  salespersonTag: SalespersonTags.MAX_VOLER;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  cardNumber: string;
  cardExpiry: string;
  cardCVC: string;
}

export type ShareInformationRes = AxiosRes & BaseRes;

/**
 * POST /access
 */
export type AccessInformationReq = {
  customerId: string;
  // timestamp: string; // will check server side, no use here
  // gymId: string; // later when we have different gyms
}

export type AccessInformationRes = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  cardNumber: string;
  cardExpiry: string;
  cardCVC: string;
  timeRemaining: number;
} & AxiosRes & BaseRes;
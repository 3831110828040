import errorIconRed from 'assets/errorIconRed.svg';
import shieldIconGreen from 'assets/shieldIconGreen.svg';
import React from 'react';

const Modal: React.FC<{ title: string; body: string; icon: "success" | "error" }> = ({ title, body, icon }) => {
  return (
    <div className="fixed inset-[40px] flex items-center justify-center">
      <div className="bg-white rounded-lg p-10 max-w-sm w-full border border-grey">
        <div className="flex flex-col">
          {icon === "success" ? (
            <img src={shieldIconGreen} alt="Success" className="w-[35px] h-[35px] mb-4" />
          ) : (
            <img src={errorIconRed} alt="Error" className="w-[35px] h-[35px] mb-4" />
          )}
          <h2 className="text-xl font-bold mb-5">{title}</h2>
          <p className="text-gray-600 text-left whitespace-pre-line">
            {body}
          </p>
        </div>
      </div>
    </div >
  );
};

export default Modal;

import React from 'react';

const PageNotFound: React.FC = () => {
  return (
    <div className="flex flex-col items-center justify-start h-screen p-10 font-sans pt-[20vh]">
      <div className="flex flex-col items-left justify-center">
        <h1 className="text-2xl font-bold mb-5">Page Not Found</h1>
        <p className="text-base text-gray-500 mb-2">
          You entered the wrong link. Please try again.
        </p>
        <p className="text-base text-gray-500 mb-2">Use the link corresponding to your sales associate:</p>
        <ul className="list-disc text-gray-500 pl-10">
          <li>
            <a href="https://ghostpay.app/crunch/max-voler" className="text-gray-500 underline">
              ghostpay.app/crunch/max-voler
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default PageNotFound;

import React from 'react';
import './styles.scss';

interface SkeletonProps {
  height?: number;
  width?: string | number;
}

const Skeleton: React.FC<SkeletonProps> = ({ height = 50, width = '100%' }) => {
  return (
    <div
      className="skeleton-loading"
      style={{ height, width }}
    />
  );
};

export default Skeleton;